
// import { useState, useEffect } from 'react';
import './App.css';
import { FaReact, FaLinkedin, FaNodeJs, FaGithub } from 'react-icons/fa';
import { SiMysql, SiMongodb, SiTypescript, SiRedux, SiAdonisjs, SiNestjs, SiJavascript, SiMobx } from 'react-icons/si';
import { Paper, Typography, Grid, Icon, Divider, Link, Container } from '@mui/material';
import { MyLocation } from '@mui/icons-material';
import CanvasComponent from './CanvasComponent';

function App() {  

  return (
    <div className="App">
      <CanvasComponent />
      <Paper
        elevation={4}
        sx={{
          padding: '2rem',
          maxWidth: '70ch',
          margin: '10%',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          maxHeight: '80vh',
          overflowY: 'auto',
          '@media(max-width: 900px)': {
            maxWidth: '80vw',
            margin: '5%',
            padding: '1rem',
            gap: '0.8rem',
            justifyContent: 'space-between',
          },
        }}
      >
        <Grid spacing={2} style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}>
          <Typography variant="h2" component="h2" sx={{
            fontFamily: 'Montserrat, sans-serif',
            '@media(max-width: 900px)': {
              fontSize: '1.5em'
            }
          }}>
            Avram Kjiprovski
          </Typography>
        </Grid>
        <Grid sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '0.5rem',
            '@media(max-width: 600px)': {
              gap: '0.3rem'
            }
          }}>
            <Typography variant="subtitle1" gutterBottom sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '0.2rem',
              flex: 1,
              fontFamily: 'Open Sans, sans-serif',
              '@media(max-width: 600px)': {
                fontSize: '.8em'
              }
            }}>
              <MyLocation />
              Skopje, North Macedonia
            </Typography>
            <Grid item>
              <Link href="https://github.com/avramkji" target="_blank">
                <FaGithub size={24} />
              </Link>
            </Grid>
          </Grid>
        <Divider />
        <Typography>  
          I am become dev.. builder of worlds
        </Typography>
      </Paper>
    </div>
  );
}

export default App;
