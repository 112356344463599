import React, { useEffect, useRef } from 'react';
const CanvasComponent = () => {
  const canvasRef = useRef(null);
  let canvas;
  let c;
  const circleCount = window.innerWidth / 3;
  const mouse = { x: null, y: null };
  const maxRadius = window.innerWidth / 29;

  const Color = {
    vector: ["#2596be", "#ffffff52"],
    getRandom: () => {
      return Color.vector[Math.floor(Math.random() * Color.vector.length)];
    }
  };

  class Circle {
    constructor(
      r_min = randomNumber(maxRadius * 7, 15),
      x = randomNumber(canvas.width, r_min),
      y = randomNumber(canvas.height, r_min),
      dx = randomNumber(1, -2, [0]),
      dy = randomNumber(1, -1, [0]),
      color = Color.getRandom()
    ) {
      this.r = r_min;
      this.x = x;
      this.y = y;
      this.dx = dx;
      this.dy = dy;
      this.color = color;
      this.draw();
    }

    side() {
      return {
        right: this.x + this.r,
        left: this.x - this.r,
        bottom: this.y + this.r,
        top: this.y - this.r
      };
    }

    draw() {
      c.beginPath();
      c.arc(this.x, this.y, this.r, 0, Math.PI * 2, false);
      c.fillStyle = this.color;
      c.fill();
    }

    run() {
      if (this.side().right > canvas.width || this.side().left < 0) this.dx *= -1;
      if (this.side().bottom > canvas.height || this.side().top < 0)
        this.dy *= -1;
      this.x += this.dx;
      this.y += this.dy;
      this.draw();
    }
  }

  let circles = [];

  const init = () => {
    canvas = canvasRef.current;
    c = canvas.getContext("2d");
    resetCanvas();
    animation();
    for (let i = circleCount; i > 0; i--) {
      circles.push(new Circle());
    }
  };

  const animation = () => {
    c.clearRect(0, 0, canvas.width, canvas.height);
    circles.forEach(function(circle) {
      circle.run();
    });
    requestAnimationFrame(animation);
  };

  const resetCanvas = () => {
    c.canvas.width = window.innerWidth;
    c.canvas.height = window.innerHeight;
  };

  const randomNumber = (max = 1, min = 0, forbidden = []) => {
    let res;
    do {
      res = Math.floor(min + Math.random() * (max - min));
    } while (forbidden.some(function(num) {
      return num == res;
    }));
    return res;
  };

  useEffect(() => {
    init();
    window.addEventListener("resize", resetCanvas);
    window.addEventListener("mousemove", function(e) {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    });
    return () => {
      window.removeEventListener("resize", resetCanvas);
      window.removeEventListener("mousemove", function(e) {
        mouse.x = e.clientX;
        mouse.y = e.clientY;
      });
    };
  }, []);

  return <canvas ref={canvasRef} style={{
    filter: 'blur(46px) saturate(1.2)',
    
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }}></canvas>;
};

export default CanvasComponent;
